<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useRoute } from 'vue-router';
import AppConfig from '@/layout/AppConfig.vue';
import { useLocaleMessages } from '@/utils/locales/useLocaleMessages';

// Components
import FunctionsSection from '@/views/public/views/landingpage/FunctionsSection.vue';
import IndsightSection from '@/views/public/views/landingpage/InsightSection.vue';
import PricingSection from '@/views/public/views/landingpage/PricingSection.vue';

// Load locales
useLocaleMessages({
    da: () => import('@/locales/da/landingPage.json'),
    en: () => import('@/locales/en/landingPage.json'),
});

// Refernces
const visible = ref(false);

// Initializations
const { layoutConfig } = useLayout();

// Computed
const darkMode = computed(() => { return layoutConfig.colorScheme.value !== 'light'; });

// lifecycle
onMounted(() => {
    const route = useRoute();
    nextTick(() => {
        if (route.hash) {
            smoothScroll(route.hash);
        }
    });
});

const smoothScroll = async (id) => {
    const element = document.querySelector(id);
    if (element) {
        element.scrollIntoView({
            behavior: 'smooth'
        });
    }
};

const openDemoVideo = () => {
    visible.value = true;
};

</script>

<template>
    <div class="py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 z-2">
        <div class="grid grid-nogutter justify-content-between align-items-center mb-6 py-6 md:mb-8 md:py-8">
            <div
                class="col-12 md:col-4 flex flex-column gap-4 flex-order-1 md:flex-order-0 align-items-center md:align-items-start text-center md:text-left">
                <span class="block text-900 font-bold text-4xl">{{ $t('title') }}</span>
                <span class="block text-700 text-lg">{{ $t('subTitle') }}</span>

                <ul id="bullet-point" class="flex flex-wrap gap-5 list-none p-0">
                    <li class="flex align-items-center">
                        <div class="p-1 border-circle bg-green-400 inline-block mr-2"></div>
                        <span class="text-900 font-semibold">{{ $t('bullet-point-1') }}</span>
                    </li>
                    <li class="flex align-items-center">
                        <div class="p-1 border-circle bg-green-400 inline-block mr-2"></div>
                        <span class="text-900 font-semibold">{{ $t('bullet-point-2') }}</span>
                    </li>
                    <li class="flex align-items-center">
                        <div class="p-1 border-circle bg-green-400 inline-block mr-2"></div>
                        <span class="text-900 font-semibold">{{ $t('bullet-point-3') }}</span>
                    </li>
                </ul>

                <!-- Demo Video Button -->
                <Button type="button" label="Se demo video" icon="pi pi-arrow-right" iconPos="right"
                    class="w-12rem p-button-outlined" @click="openDemoVideo" />
            </div>

            <div class="col-12 md:col-7 flex-order-0 md:flex-order-1 mb-6 md:mb-0 border-round">
                <img :src="`/images/landingPage/kontrolpanel4.png`" alt=""
                    class="w-full h-full border-round shadow-2 animation-duration-1000 fadeinright" />
            </div>
        </div>

        <section id="apps" class="my-6 md:my-8">
            <FunctionsSection />
        </section>

        <section id="features" class="my-6 py-6 md:my-8 md:py-8">
            <IndsightSection />
        </section>

        <section id="pricing" class="my-6 py-6 md:my-8 md:py-8">
            <PricingSection />
        </section>

        <!-- <section class="grid justify-content-between my-6 pt-4 md:my-8">
                <FooterSection />
            </section> -->
    </div>
    <AppConfig simple />
    <Dialog v-model:visible="visible" closeOnEscape dismissableMask modal :draggable="false" header=" "
        :style="{ width: '80%' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <video width="100%" controls>
            <source src="https://storage.googleapis.com/minmarketing_video_public/minmarketing-intro.mp4"
                type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </Dialog>
</template>

<style scoped>
.montserrat-font {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    /* Bold */
}
</style>